<template>
  <main class="product-level1-page page-new-product">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">Sản phẩm</h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>
    <div class="product-page-header_wrap">
      <h1 class="title">Tất cả sản phẩm</h1>
      <h2 class="subtitle">Các sản phẩm đến từ các thương hiệu Việt Nam</h2>
    </div>
    <div class="page-nav-bar-sticky">
      <div
        class="page-nav-bar dosiin_page-nav-bar dosiin_filter-result-nav-bar filter-result-nav-bar"
      >
        <div class="total-result" style="display: flex">
          <b class="total-product">{{ numberFormat(totalProducts) }}</b> &nbsp;sản phẩm
        </div>
        <button class="nav-bar_filter" type="button" @click="openFilters">
          <div class="divider"></div>
          <span class="text">Bộ lọc</span>
          <i class="dsi dsi-filter-setting"></i>
        </button>
      </div>
    </div>
    <section class="page-section">
      <h2 class="title-product-level1-view-all">Sản phẩm</h2>
      <ProductInifityLoader
        :query="params"
        gridClass="grid-20"
        :changeGrid="true"
        @total-products="updateTotals"
        @max-price="(max) => (maxPrice = max)"
      />
    </section>

    <MainFilters
      v-model="params"
      :totalProducts="totalProducts"
      filterModalClass="modal-sidebar-right"
      ref="mainFilters"
      :maxPrice="maxPrice"
    />
  </main>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import MainFilters from "@/components/filters/Filters";
import { ref, inject } from "vue";
import ProductInifityLoader from "@/components/product/ProductInifityLoader";

export default {
  name: "AllProducts",
  components: {
    BackHeader,
    MainFilters,
    ProductInifityLoader,
  },
  setup() {
    const mainFilters = ref(null);
    const totalProducts = ref(0);
    const params = inject("params");
    const maxPrice = ref(0);
    const openFilters = () => {
      mainFilters.value.show = !mainFilters.value.show;
    };

    const updateTotals = (total) => {
      totalProducts.value = total;
    };

    return {
      params,
      maxPrice,
      totalProducts,
      // refs
      mainFilters,
      //methods
      openFilters,
      updateTotals,
    };
  },
};
</script>
