<template>
  <ProductsMB v-if="layout == 4" />
  <ProductsPC v-else />
</template>

<script>
import ProductsMB from "@/pages/mb/Products";
import ProductsPC from "@/pages/pc/Products";
import { ref, provide } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AllProducts",
  components: {
    ProductsMB,
    ProductsPC,
  },
  setup() {
    const route = useRoute();
    const params = ref({
      page: 1,
      type_category: route.query.type_category ? route.query.type_category : "",
      sort_by: route.query.sort_by ? route.query.sort_by : "random", //popularity , percent_discount
      sort_order: route.query.sort_order ? route.query.sort_order : "desc",
      sizes: route.query.sizes ? route.query.sizes : [],
      company_ids: route.query.company_ids ? route.query.company_ids : [],
      cid: route.query.cid ? route.query.cid : [],
      color: route.query.color ? route.query.color : [],
      on_sale_from: route.query.on_sale_from ? route.query.on_sale_from : null,
      on_sale_to: route.query.on_sale_to ? route.query.on_sale_to : null,
    });

    provide("params", params);
  },
};
</script>
