<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <div class="dosiin_wrapper-bar dosiin_mb-16">
      <Breadcrumbs :breadcumbs="breadcumbs" />

      <div class="dosiin-tab-bar-pc">
        <div class="tab-bar-pc-content">
          <div class="title-page d-flex align-items-center justify-content-between">
            <div class="title-all-categories">Sản phẩm</div>
            <div class="tab-bar-content-quantity">
              <span class="quantity" v-text="numberFormat(totalProducts)"></span>
              <span class="text-quantity">Sản phẩm tìm thấy</span>
            </div>
          </div>
        </div>

        <FitersBarPc :maxPrice="maxPrice" :totalProducts="totalProducts" />
      </div>
    </div>

    <div class="page-section">
      <ProductInifityLoader
        :query="params"
        @total-products="(total) => (totalProducts = total)"
        @max-price="(max) => (maxPrice = max)"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import ProductInifityLoader from "@/components/product/ProductInifityLoader";
import FitersBarPc from "@/components/filters/FiltersBarPc";

export default {
  name: "AllProducts",
  components: {
    ProductInifityLoader,
    FitersBarPc,
  },
  setup() {
    const params = inject("params");
    const totalProducts = ref(0);
    const maxPrice = ref(0);
    const mainFilters = ref(null);
    const breadcumbs = [
      { title: "Shops", route: "/shops" },
      { title: "Tất cả sản phẩm", route: "#" },
    ];

    return {
      params,
      breadcumbs,
      totalProducts,
      maxPrice,
      //refs
      mainFilters,
    };
  },
};
</script>
